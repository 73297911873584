.App {
  font-family: sans-serif;
  text-align: center;
}

button {
  margin: 2px;
  padding: 5px;
  min-width: 30px;
}
